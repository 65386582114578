.fidcl-accordion-card {
  width: auto;
  background: rgba(255, 255, 255, 0);
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0);
  gap: 20px;
}

.fidcl-accordion-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: #ffffff solid 1px;
}
.fidcl-accordion-card-title {
  font-family: 'OpenSans-SemiBold', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.fidcl-accordion-card-toggle-button > button {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 4px;
}
.fidcl-accordion-card-action-button {
  width: fit-content;
}
.fidcl-accordion-card-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    margin-top 0.3s ease-out;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
}

.fidcl-accordion-card-description.show {
  margin-top: 16px;
  max-height: 500px;
}

@media only screen and (max-width: 1279px) {
  .fidcl-accordion-card {
    padding: 0px;
  }
  .fidcl-accordion-card-header {
    padding-bottom: 16px;
  }
  .fidcl-accordion-card-description.show {
    margin-top: 0;
  }
}
