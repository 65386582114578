.no-default-style-action-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.no-default-style-action-btn:focus {
  outline: none;
}
