/* Navbar styles */
.cenco-navbar {
  display: -webkit-box; /* Antiguos navegadores WebKit */
  display: -ms-flexbox; /* Antiguos IE */
  display: flex;
  position: fixed;
  top: 29px;
  padding: 12px 32px;
  width: 100%;
  -webkit-box-orient: vertical; /* Antiguos navegadores WebKit */
  -webkit-box-direction: normal; /* Antiguos navegadores WebKit */
  -ms-flex-direction: column; /* Antiguos IE */
  flex-direction: column;
  gap: 8px;
  -webkit-box-pack: justify; /* Antiguos navegadores WebKit */
  -ms-flex-pack: justify; /* Antiguos IE */
  justify-content: space-between;
  -webkit-box-align: center; /* Antiguos navegadores WebKit */
  -ms-flex-align: center; /* Antiguos IE */
  align-items: center;
  background-color: #200e3b;
  color: #ffffff;
  height: 80px;
  font-size: 16px;
  font-style: normal;
  font-family: OpenSans-SemiBold, Serif;
  line-height: 24px;
  z-index: 2;
}

.cenco-navbar.top {
  position: fixed;
}
.cenco-navbar.down {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: visibility 0.5s, opacity 0.5s linear; /* Antiguos navegadores WebKit */
  transition: visibility 0.5s, opacity 0.5s linear;
}
.cenco-navbar.up {
  position: fixed;
  top: 29px;
  right: 0;
  left: 0;
  -webkit-transition-property: position, top; /* Antiguos navegadores WebKit */
  transition-property: position, top;
  -webkit-transition-duration: 0.5s; /* Antiguos navegadores WebKit */
  transition-duration: 0.5s;
}

.points-navbar-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.cenco-nav-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.display-flex-desk-mobile {
  display: none;
}

.cenco-nav-sub-container {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;
  white-space: nowrap;
}

.logo-menu-mb {
  display: flex;
}

.cenco-nav-sub-container-responsive-m {
  display: none;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.cenco-nav-button-container {
  min-width: 260px;
}

/* Logo styles */
.logo {
  font-weight: bold;
}
.logo-img {
  width: 60px;
}

.search-navbar {
  margin-top: 5px;
  height: 44px;
  min-width: 300px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* Other button styles */
button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

/* Search box styles */
input {
  padding: 5px;
}

/* Dialog styles */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 999;
}

.dialog button {
  background-color: #333;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.burger-menu {
  display: none;
}
.nav-option-container {
  display: flex;
  gap: 16px;
  align-items: center;
}
display-block-desk {
  display: block !important;
}
.display-flex-desk {
  display: flex !important;
}

.account-sigin-mobile-menu {
  display: none;
}

.btn-header-navbar:focus {
  outline: none !important;
}

.display-none-desk {
  display: none !important;
}
@media only screen and (max-width: 1279px) {
  .display-flex-desk-mobile {
    display: flex;
  }
  .display-none-mobile {
    display: none !important;
  }
  .display-flex-mobile {
    display: flex !important;
  }
  .display-block-mobile {
    display: block !important;
  }
  .cenco-nav-container {
    justify-content: normal;
  }

  .cenco-navbar {
    height: 160px;
    padding: 12px 16px;
    z-index: 6;
  }

  .cenco-nav-button-container {
    min-width: 0;
  }

  .cenco.cenco-nav-container {
    flex-direction: column;
    -webkit-box-align: flex-start; /* Antiguos navegadores WebKit */
    -ms-flex-align: flex-start; /* Antiguos IE */
    align-items: flex-start;
  }

  .nav-option-container {
    display: none;
  }

  .burger-menu {
    display: block;
    margin-left: -5px;
  }

  .logo-img {
    width: 50px;
    height: 50px;
  }
  .cenco-nav-sub-container {
    gap: 0px;
    justify-content: space-between;
  }
  .cenco-nav-sub-container-responsive-m {
    display: flex;
  }
  .account-sigin-mobile-menu {
    display: flex;
    z-index: 1;
  }
  .points-navbar-mobile {
    display: flex;
  }
}
