.slick-slider {
  position: relative;
}

.slick-list {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  max-height: 400px;
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  flex: 1;
  align-items: center;
  justify-content: flex-start;

  flex-direction: row;
  flex-wrap: nowrap;
}

.slick-slide {
  width: 1230px;

  margin: 0 auto;
  text-align: center !important;
}

.slick-slide a {
  overflow: hidden;
}

.slick-dots {
  position: absolute !important;
  bottom: 0px !important;
  box-sizing: border-box;
  padding: 0 10px !important;
}

.slick-dots li {
  height: 10px !important;
}

.slick-arrow {
  width: 50px !important;
  background: transparent !important;
  z-index: 2;
  height: 100% !important;
  top: 0 !important;
  transform: translate(0, 0%) !important;
}

.slick-prev {
  /* left: -50px !important; */
  left: 0 !important;
}

.slick-next {
  /* right: -50px !important; */
  right: 0 !important;
}

.slick-arrow:before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '' !important;
  display: inline-block;
  height: 20px;
  left: 0;
  position: relative;
  top: 0;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 20px;
  border-color: #743387;
}

.slick-prev:before {
  transform: rotate(-135deg);
}

.slick-next:before {
  transform: rotate(45deg);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background: transparent !important;
}

/*Media queries*/

/* Smartphones (not mobile first) ----------- */
/* @media only screen and (max-width : 480px) { */
@media only screen and (max-width: 1200px) {
  .slick-list {
    width: 100%;
    max-height: 100%;
    padding: 0;
  }

  .slick-slider {
    width: 100%;
    max-height: 100%;
  }

  .slick-slider a img {
    height: 33vw;
  }
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-slide {
  width: 340px !important;
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-prev {
  left: -50px !important;
}

.ProductoListCarrusel__ContainerSlider-sc-4hgt34-0 .slick-next {
  right: -50px !important;
}
