.cenco-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.cenco-blur {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-Medium, Serif;
  line-height: 27px;
  z-index: 0;
  backdrop-filter: blur(5px);
}
