.online-info-card-exchange {
  box-sizing: border-box;
  display: flex;
  padding: 12px;
  border: 1px solid #dbdee5;
  border-radius: 10px;
}

.online-info-card-exchange-div-row {
  align-items: flex-start;
}

.online-info-card-exchange-div-row-with-image {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 12px;
}

.online-info-card-exchange-div-row-collapsible {
  display: flex;
  justify-content: space-between;
}

.online-info-card-exchange-icon {
  margin-right: 10px;
}

.online-info-card-exchange-image {
  object-fit: contain;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  width: 100px;
  height: 100px;
  border: 1px solid #dbdee5;
  border-radius: 4px;
}

.online-info-card-exchange-div {
  display: flex;
  flex-direction: column;
}

.online-info-card-exchange-title {
  font-family: 'OpenSans-Bold', Serif;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
}

.online-info-card-exchange-subtitle-on-the-right {
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212529;
}

.exchange-card-line {
  position: relative;
}

.exchange-card-line-one {
  height: 2px;
  background: #dbdee5;
  border-radius: 4px;
}

.exchange-card-line-two {
  width: 14%;
  height: 2px;
  background: #783184;
  border-radius: 4px;
  top: 0;
  position: absolute;
}

.online-info-card-exchange-div-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
}

.online-info-card-exchange-text {
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.online-info-card-exchange-subtitle {
  width: 100%;
  font-family: 'OpenSans-SemiBold', Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
}

.online-info-card-exchange-item {
  width: 100%;
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  padding-bottom: 8px;
}

.exchange-card-badge-canje-procesado {
  background: #d2f4e9 !important;
  color: #017753;
}

.exchange-card-badge-canje-cancelado {
  background: #fef3f2 !important;
  color: #b93615;
}

.exchange-card-badge-canje-en-proceso {
  background: #eff8ff !important;
  color: #101828;
}

.exchange-card-content {
  transition: height 0s ease-in-out 0s;
  overflow: hidden;
}

.online-info-card-exchange-symbol-on-the-right {
  width: 24px;
  height: 24px;
}

.skeleton-exchange-card-footer {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
}

@media only screen and (max-width: 1279px) {
  .online-info-card-exchange {
    width: 100%;
  }
  .skeleton-exchange-card-footer {
    flex-direction: column;
  }
}
