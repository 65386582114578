.activo {
  background-color: hsla(0, 0%, 85%, 0.73) !important;
}

.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
}

.texto-flotante {
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-10%, -30%);
  color: #9e9e9e;
  font-size: 10px;
  white-space: nowrap;
}

.contenedor-botones-recuperacion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  z-index: 0;
}

.warning-style {
  padding: 15px;
  margin: 10px 0px 10px 0px !important;
  border-radius: 0.375rem;
  background: #fff5cc !important;
  color: #c7a200 !important;
}
