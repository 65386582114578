.swal-button {
  /* Todos los botones de sweetalert */
  padding: 10px 12px;
  align-items: center;
  border-radius: 4px;
  background: #200e3b;
  color: #ffffff;
  border: 1px solid #200e3b;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
}
.swal-button:focus {
  background: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #f4ebff;
  outline: none;
}

.swal-title {
  color: #212529;
  color: var(--neutrales-gray-1000, #212529);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.swal-title:first-child {
  margin-top: 0 !important;
}
.swal-text {
  font-size: 16px;
  position: relative;
  text-align: center;
  /* line-height: normal; */
  /* vertical-align: bottom; */
  text-align: center;
  /* display: inline-block; */
  /* padding: 0 16px; */
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  max-width: calc(100% - 20px);
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.swal-button:hover:not([disabled]) {
  background: #6b3c9a;
  border-color: #6b3c9a;
}

.actualizar-datos__alert-button--salir {
}

.swal-button--confirm {
}
.swal-button--confirm:hover,
.swal-button--confirm:active {
}

.swal-button--cancel {
  color: #200e3b;
  background: #ffffff;
  border-color: #200e3b;
}
.swal-button--close {
  background: transparent;
  position: absolute;
  color: #333;
  opacity: 1;
  top: 5;
  right: 5;
}
.swal-button--cancel:hover,
.swal-button--cancel:active {
  background: #c4c5c9 !important;
  border-color: #200e3b !important;
}

.swal-button--cancel:focus {
  background: #ffffff;
  border-color: #200e3b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.25), 0px 0px 0px 4px #ffffff;
  outline: none;
}
.swal-button-disabled {
  pointer-events: none;
  cursor: default;
  background: #6c757d;
  border-color: #6c757d;
}
.swal-footer {
  text-align: center;
  padding: 15px 0 25px 0;
}

.swal-modal {
  width: 310px;
}

.swal-content {
  padding: 0 15px;
  margin: 15px 0;
}
.red-bg {
  background: red;
}
.footer-flex > .swal-footer {
  /* Estilos personalizados para el footer */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 0 10px 0;
  /* Agrega otros estilos según tus necesidades */
}
.fixed-bottom > .swal-overlay--show-modal .swal-modal {
  position: fixed;
  bottom: -22px;
  left: 0;
  width: 100%;
  opacity: 1;
  pointer-events: auto;
  box-sizing: border-box;
  -webkit-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
  will-change: transform;
}
.fixed-bottom > .swal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 0 10px 0;
}
.fixed-bottom {
  position: fixed;
  align-items: center;
  text-align: center;
  bottom: -22px;
  left: 0;
  width: 100%;
}
.full-width-button {
  width: 100%;
}

@keyframes slideDownToUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.swal-custom-animation {
  animation: slideDownToUp 0.3s ease-out;
}
