.div-row-container {
  display: flex;
  flex-direction: row;
}
.drc-left {
  justify-content: end;
}
.drc-between {
  justify-content: space-between;
}
.div-column-container {
  display: flex;
  flex-direction: column;
}
