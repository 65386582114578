.point-container {
  margin-left: 3px;
  margin-right: 3px;
  align-items: center;
  display: flex;
}
.point-account-status {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.point-account-status-plus {
  color: #783184;
}
.point-account-status-minus {
  color: #444e63;
}
