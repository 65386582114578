.exchange-successfully-tooltip .title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: start;
}

.exchange-successfully-tooltip .description {
  font-size: 14px;
  font-weight: normal;
  text-align: start;
}

.exchange-successfully-tooltip .close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.exchange-successfully-tooltip .arrow {
  border: none;
  padding: 0;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #783184;
}

@media only screen and (hover: none) {
  @media (max-width: 1279px) {
    .bs-tooltip-top .arrow,
    .bs-tooltip-auto[x-placement^='top'] .arrow {
      bottom: 0;
    }

    .bs-tooltip-top .arrow::before,
    .bs-tooltip-auto[x-placement^='top'] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #783184;
    }
  }
}
