.divider-container {
  width: 50%;
  height: 4px;
  border-radius: 8px;
  flex: none;
  flex-grow: 0;
  margin-bottom: 10px;
  display: flex;
}

.divider-progress {
  height: 2px;
  left: 0%;
  right: 85.97%;
  top: 2px;
  background: #200e3b;
  border-radius: 4px;
  display: flex;
}

.divider-background {
  width: 100%;
  height: 2px;
  left: 0px;
  right: 0px;
  top: 2px;
  background: #eaebec;
  border-radius: 4px;
}

@media (max-width: 767px) {
  .divider-container {
    width: 90%;
  }
}
