/* LOADING MESSAGE */

.fidcl-loading-message {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 30rem;
  height: 14rem;
}

.fidcl-loading-message > p {
  color: #212529 !important;
  padding: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.4;
}

.fidcl-loading-message .loader-container {
  text-align: center;
}

.fidcl-loading-message .loader-container .spinner {
  display: inline-block;
}

@media (max-width: 1024px) {
  .fidcl-loading-message {
    position: relative;
    width: 100%;
  }
}
