.fidcl-close {
  display: none !important;
}
.fidcl-healding-main {
  color: #522e76;
  margin: 3rem 0;
  font-size: 3rem;
}
.fidcl-bg-success-primary {
  background-color: #0dab59;
}
.fidcl-bg-success-secondary {
  background-color: #d9f2e5;
}
.fidcl-bg-warn-primary {
  background-color: #c7a200;
}
.fidcl-bg-warn-secondary {
  background-color: #fff5cc;
}
.fidcl-bg-danger-primary {
  background-color: #f5242e;
}
.fidcl-bg-danger-secondary {
  background-color: #fddee0;
}
.fidcl-bg-info-primary {
  background-color: #6e2f86;
}
.fidcl-bg-info-secondary {
  background-color: #e9e0ed;
}
.fidcl-clr-primary {
  color: #522e76;
}
.fidcl-clr-success-primary {
  color: #0dab59;
}
.fidcl-clr-success-secondary {
  color: #d9f2e5;
}
.fidcl-clr-warn-primary {
  color: #c7a200;
}
.fidcl-clr-warn-secondary {
  color: #fff5cc;
}
.fidcl-clr-danger-primary {
  color: #f5242e;
}
.fidcl-clr-danger-secondary {
  color: #fddee0;
}
.fidcl-clr-info-primary {
  color: #6e2f86;
}
.fidcl-clr-info-secondary {
  color: #e9e0ed;
}
.fidcl-border-success-primary {
  border-color: #0dab59;
}
.border-success-secondary {
  border-color: #d9f2e5;
}
.fidcl-border-warn-primary {
  border-color: #c7a200;
}
.fidcl-border-warn-secondary {
  border-color: #fff5cc;
}
.fidcl-border-danger-primary {
  border-color: #f5242e;
}
.fidcl-border-danger-secondary {
  border-color: #fddee0;
}
.fidcl-border-info-primary {
  border-color: #6e2f86;
}
.fidcl-border-info-secondary {
  border-color: #e9e0ed;
}
.fidcl-pagination {
  float: right;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.fidcl-pagination .page-link {
  font-size: 1.6rem;
  position: relative;
  display: block;
  padding: 0.6rem 1rem 0.4rem;
  line-height: 2rem;
  color: hsla(0, 0%, 80%, 0.95);
  background-color: #fff;
  border: 1px solid hsla(0, 0%, 80%, 0.95);
  text-decoration: none;
  font-weight: 200;
}
.fidcl-pagination .page-link svg {
  height: 1.7rem;
  width: auto;
}
.fidcl-pagination .page-link svg path {
  fill: hsla(0, 0%, 80%, 0.95);
}
.fidcl-pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #522e76;
  border-color: #522e76;
}
.fidcl-pagination .page-link.fidcl-pagination-icon {
  padding: 0.48rem 0.5rem 0.5rem 0.6rem;
  height: 3.2rem;
}
.page-item:not(:disabled):not(.disabled):not(.active):hover * {
  background-color: #f9f9f9;
  color: #522e76;
}
.page-item:not(:disabled):not(.disabled):not(.active):hover path {
  fill: #522e76;
}
.page-item.active {
  border-right: solid #522e76;
  z-index: 3;
}
.fidcl-header-und {
  font-size: 2.4rem;
  color: #522e76;
  margin-bottom: 1.5rem;
}
.fidcl-header-und img {
  width: 4.5rem;
  margin-top: -0.5rem;
}
.product-container {
  background: #e4e4e4;
}
.col-2_4 {
  width: 20%;
  padding: 0 1.5rem;
}
.fidcl-table-responsive {
  width: 100%;
  overflow: auto;
}
.fidcl-table {
  font-size: 1.4rem;
  width: 100%;
  margin: 3rem 0;
}
.fidcl-table thead {
  background: #e9e0ed;
  color: #522e76;
}

.fidcl-th-number {
  text-align: right;
}
.fidcl-td-action {
  text-align: center;
  padding: 0 !important;
}
.fidcl-td-number {
  text-align: right;
}
.fidcl-table-card {
  margin: 0;
}
.fidcl-table-card td,
.fidcl-table-card th {
  border: none;
  border-bottom: 0.1rem solid #e9e0ed;
  padding: 1.5rem;
}
.fidcl-table-card th {
  background: #fff;
  font-weight: 800;
  color: #666;
}
.fidcl-table-card tbody tr:hover {
  background: #f9f9f9;
}
.fidcl-table-card tr:last-child td {
  border-bottom: none;
}
.fidcl-table-data {
  margin: 0;
}
.fidcl-table-data td,
.fidcl-table-data th {
  border: none;
  border-bottom: 0.1rem solid #e9e0ed;
  padding: 1.5rem;
}
.fidcl-table-data th {
  background: #fff;
  font-weight: 800;
  color: #666;
}
.fidcl-table-data tbody tr:hover {
  background: #f9f9f9;
}
.fidcl-table-data tr:last-child td {
  border-bottom: none;
}
@media (min-width: 768px) {
  .success-container {
    margin-top: 5rem;
  }
  .hide-desktop {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .success-container {
    margin-top: 10rem;
  }
  .col-2_4 {
    width: 100%;
  }
  .mx-mobile-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .pt-mobile-4 {
    padding-top: 1.5rem;
  }
  .show-desktop {
    display: none;
  }
  .product-container {
    background: #fff;
    margin-left: 0;
    margin-right: 0;
  }
  .hide-mobile {
    display: none !important;
  }
  .fidcl-header-und {
    font-size: 2rem;
    color: #522e76;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .fidcl-header-und img {
    width: 4rem;
    margin-top: -0.5rem;
  }
}
.fidcl-close-css {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  width: 3rem;
  height: 3rem;
}
.fidcl-close-css:hover {
  opacity: 1;
}
.fidcl-close-css:after,
.fidcl-close-css:before {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 3rem;
  width: 2px;
  background-color: #522e76;
}
.fidcl-close-css:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.fidcl-close-css:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.form-container {
  max-width: 50rem;
  margin: auto;
}
.btn {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 0 1.5rem;
  -webkit-transition: border-color 0.1s ease-in;
  transition: border-color 0.1s ease-in;
  width: 100%;
}
.fidcl-display-test-secure-password {
  padding: 0;
}
.fidcl-display-test-secure-password li {
  font-size: 1.4rem;
  list-style-type: none;
  margin-top: 0.25rem;
}
.modal-body {
  padding: 1.5rem;
}
.modal-header .close {
  font-size: 3rem;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #522e76;
  font-size: 2rem;
  margin-top: 0;
}
.mt-6 {
  margin-top: 6rem;
}
.fidcl-terms-container.fidcl-modal {
  max-width: 125rem;
  overflow: hidden;
  height: 95%;
}
.fidcl-terms-container.fidcl-modal .modal-content {
  height: 100%;
}
.fidcl-terms-container.fidcl-modal .modal-body {
  height: 100%;
  overflow: auto;
}
.close {
  font-weight: 100;
}
.f-none {
  -webkit-flex: none !important;
  flex: none !important;
}
.goto-cenco-container {
  padding-right: 3.5rem;
}
.goto-cenco-container > a {
  display: inline-block;
}
.fidcl-input {
  text-align: left;
  min-height: 10rem;
}
.fidcl-input-container {
  position: relative;
}
.fidcl-input label {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
.fidcl-input input {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  border: 0.1rem solid #9e9e9e;
  padding: 0 1.5rem;
  -webkit-transition: border-color 0.1s ease-in;
  transition: border-color 0.1s ease-in;
  width: 100%;
}
.fidcl-input .fidcl-input-before-icon-container input {
  padding-left: 4.8rem;
}
.fidcl-input-before-icon {
  position: absolute;
  top: 0.8rem;
  left: 1.5rem;
  pointer-events: none;
}
.fidcl-input .fidcl-input-after-icon-container input {
  padding-right: 4.8rem;
}
.fidcl-input-after-icon {
  position: absolute;
  top: 0.8rem;
  right: 1.5rem;
  pointer-events: none;
}
.fidcl-input input:hover {
  border-color: #522e76;
  -webkit-transition: border-color 0.1s ease-in;
  transition: border-color 0.1s ease-in;
}
.fidcl-input input:focus {
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
.fidcl-input input:active,
.fidcl-input input:focus {
  -webkit-transition: box-shadow 0.1s ease-in;
  transition: box-shadow 0.1s ease-in;
}
.fidcl-input input:active {
  box-shadow: 0 0 0 3px #ebe2f4;
}
.fidcl-input input:disabled {
  background-color: #f3f3f3;
}
.fidcl-input input:disabled:hover {
  border-color: #525252;
}
.fidcl-input input:disabled:focus {
  box-shadow: 0 0 0 2px #ebebeb;
}
.fidcl-input input:disabled:active {
  box-shadow: 0 0 0 3px #ebebeb;
}
.fidcl-input-before-icon-actionable {
  cursor: pointer;
  color: #522e76;
}
.fidcl-input-before-icon-actionable-password {
  pointer-events: all;
}
.fidcl-input.fidcl-error input {
  border-color: #f5242e;
}
.fidcl-input.fidcl-success input {
  border-color: #00943e;
}
.fidcl-input.fidcl-error input:disabled:hover {
  border-color: #525252;
}
.fidcl-input.fidcl-error input:focus {
  box-shadow: 0 0 0 2px #fdd8da;
}
.fidcl-input.fidcl-error input:active {
  box-shadow: 0 0 0 3px #fdd8da;
}
.fidcl-input.fidcl-success input:focus {
  box-shadow: 0 0 0 2px #c7ffde;
}
.fidcl-input.fidcl-success input:active {
  box-shadow: 0 0 0 3px #c7ffde;
}
.fidcl-error-text {
  color: #f5222d;
  font-size: 1.4rem;
  margin-top: 0.25rem;
}
.fidcl-sucess-text {
  color: #00923e;
  font-size: 1.4rem;
  margin-top: 0;
}
.fidcl-input-tel .fidcl-input-before-icon {
  font-size: 1.4rem;
  top: 1.06rem;
  color: #9e9e9e;
}
.fidcl-input-tel input {
  padding-left: 5.5rem !important;
}
.fidcl-button {
  -webkit-flex: auto;
  flex: auto;
}
.fidcl-button button {
  border: 0.1rem solid #522e76;
  background-color: #522e76;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
  min-height: 4rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  display: inline-block;
  width: auto;
}
.fidcl-button .fidcl-button-block {
  width: 100%;
}
.fidcl-button button:hover {
  background-color: #6b3c9a;
}
.fidcl-button button:focus,
.fidcl-button button:hover {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #522e76;
}
.fidcl-button button:focus {
  background-color: #381f51;
  outline: none;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:active {
  background-color: #381f51;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  box-shadow: 0 0 0 3px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:not(:disabled):not(.disabled).active:focus,
.fidcl-button button:not(:disabled):not(.disabled):active:focus {
  background-color: #381f51;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
.fidcl-button button:not(:disabled):not(.disabled).active,
.fidcl-button button:not(:disabled):not(.disabled):active {
  background-color: #381f51;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  box-shadow: 0 0 0 3px rgba(82, 46, 118, 0.4);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
button.fidcl-button-secondary-outline {
  color: #522e76;
  background: #fff;
}
button.fidcl-button-secondary-outline:hover {
  color: #fff;
  border-color: #522e76;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  background-color: #522e76;
}
button.fidcl-button-secondary-outline:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(82, 46, 118, 0.4);
}
button.fidcl-button-secondary-outline:active,
button.fidcl-button-secondary-outline:focus {
  color: #fff;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
button.fidcl-button-table-outline {
  color: #522e76;
  background: #fff;
  border-color: #fff;
  height: 3.3rem;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  width: auto;
  min-height: auto;
}
table tr td button.fidcl-button-table-outline {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
table tr:hover td button.fidcl-button-table-outline {
  color: #fff;
  border-color: #522e76;
  background-color: #522e76;
}
button.fidcl-button-table-outline:focus {
  outline: none;
  border-color: #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
button.fidcl-button-table-outline:active,
button.fidcl-button-table-outline:focus {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  color: #522e76 !important;
  background-color: #fff !important;
}
button.fidcl-button-table-outline:active {
  box-shadow: 0 0 0 3px #ebe2f4;
}
.fidcl-button button.fidcl-button-warn {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #c7a200;
  background-color: #c7a200;
}
.fidcl-button button.fidcl-button-warn:hover {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #e0b700;
  background-color: #e0b700;
}
.fidcl-button button.fidcl-button-warn:active,
.fidcl-button button.fidcl-button-warn:focus,
.fidcl-button button.fidcl-button-warn:not(:disabled):not(.disabled):active {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #bd9c0a;
  background-color: #bd9c0a;
  box-shadow: 0 0 0 3px rgba(199, 162, 0, 0.4);
}
.fidcl-button button.fidcl-button-danger {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #f5242e;
  background-color: #f5242e;
}
.fidcl-button button.fidcl-button-danger:hover {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #f7555d;
  background-color: #f7555d;
}
.fidcl-button button.fidcl-button-danger:active,
.fidcl-button button.fidcl-button-danger:focus,
.fidcl-button button.fidcl-button-danger:not(:disabled):not(.disabled):active {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #ff1a25;
  background-color: #ff1a25;
  box-shadow: 0 0 0 3px rgba(245, 36, 46, 0.4);
}
.fidcl-button button.fidcl-button-success {
  border-color: #0dab59;
  background-color: #0dab59;
}
.fidcl-button button.fidcl-button-success:hover {
  border-color: #04b459;
  background-color: #04b459;
}
.fidcl-button button.fidcl-button-success:active,
.fidcl-button button.fidcl-button-success:focus,
.fidcl-button button.fidcl-button-success:not(:disabled):not(.disabled):active {
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
  border-color: #16a259;
  background-color: #16a259;
  box-shadow: 0 0 0 3px rgba(13, 171, 89, 0.4);
}
.fidcl-button.fidcl-button-sm button {
  min-height: 3.2rem;
  height: 3.2rem;
}
.fidcl-button-und > button {
  min-width: 12rem;
  margin: 0 0 0 3rem !important;
}
.fidcl-button-und {
  position: relative;
}
.fidcl-button-und .logo {
  position: absolute;
  content: '';
  right: 10rem;
  bottom: 0;
  height: 4.2rem;
  width: 4.2rem;
  margin: auto;
  border-radius: 50%;
}
.fidcl-button-paris button {
  color: #009de0;
  border-color: #009de0;
}
.fidcl-button-paris.active button,
.fidcl-button-paris button:hover {
  background: #009de0;
  border-color: #009de0;
  color: #fff;
}
.fidcl-button-jumbo button {
  color: #00923e;
  border-color: #00923e;
}
.fidcl-button-jumbo button:hover {
  border-color: #00923e;
  background: #00923e;
}
.fidcl-button-santa-isabel button {
  color: #e20612;
  border-color: #e20612;
}
.fidcl-button-santa-isabel button:hover {
  border-color: #e20612;
  background: #e20612;
}
.fidcl-button-easy button {
  color: #e30917;
  border-color: #e30917;
}
.fidcl-button-easy button:hover {
  border-color: #e30917;
  background: #e30917;
}
.fidcl-button-johnson button {
  color: #ef7e00;
  border-color: #ef7e00;
}
.fidcl-button-johnson button:hover {
  border-color: #ef7e00;
  background: #ef7e00;
}
.colores-colores {
  color: #0dab59;
  color: #0caa59;
  color: #04b459;
  color: #03b358;
  color: rgba(13, 171, 89, 0.4);
  color: rgba(12, 170, 89, 0.4);
  color: #c7a200;
  color: #c6a200;
  color: #e0b700;
  color: rgba(199, 162, 0, 0.4);
  color: rgba(198, 162, 0, 0.4);
  color: #f5242e;
  color: #f4232e;
  color: #f7555d;
  color: #f6555d;
  color: rgba(245, 36, 46, 0.4);
  color: rgba(244, 35, 46, 0.4);
  color: #6c2e84;
  color: #6b2e84;
  color: #6b3c9a;
  color: #6b3b9a;
  color: rgba(108, 46, 132, 0.4);
  color: rgba(107, 46, 132, 0.4);
  color: #e8dfec;
  color: #e9e0ed;
}
.fidcl-button-default > i {
  position: absolute;
  right: 3rem;
}
.fidcl-checkbox {
  padding: 1.5rem 0 3rem 3.5rem;
  position: relative;
}
.fidcl-checkbox > span {
  vertical-align: top;
  display: inline-block;
  padding-top: 0.6rem;
  font-size: 1.4rem;
}
.fidcl-checkbox-item {
  display: inline-block;
  position: absolute;
  width: 2.2rem;
  border: 0.1rem solid #9e9e9e;
  height: 2.2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  top: 1.9rem;
  left: 0;
}
.fidcl-checkbox-item-uncheck svg {
  opacity: 0;
  margin: -0.1rem 0 0 0.1rem;
  color: #fff;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  width: 1.9rem;
}
.fidcl-checkbox-item-uncheck:hover {
  border: 0.1rem solid #522e76;
}
.fidcl-checkbox-item-check {
  border: 0.1rem solid #522e76;
  background: #522e76;
}
.fidcl-checkbox-item-check svg {
  opacity: 1;
  margin: -0.1rem 0 0 0.1rem;
  color: #fff;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  width: 1.9rem;
}
.fidcl-checkbox-item input[type='checkbox'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  zoom: 5;
  position: absolute;
  top: -1rem;
  left: -1rem;
  -webkit-transform: scale(2);
  transform: scale(2);
}
.focus .fidcl-checkbox-item {
  border: 0.1rem solid #522e76;
  box-shadow: 0 0 0 2px #ebe2f4;
}
.fidcl-terms h2 {
  font-size: 2rem;
}
.fidcl-terms h3 {
  font-size: 1.6rem;
}
.fidcl-error-message {
  background-color: #fddddf;
  border-radius: 0.5rem;
  color: #f5242e;
  font-size: 1.4rem;
  position: relative;
  padding: 1.5rem 1.5rem 1.5rem 5rem;
}
.fidcl-error-message svg {
  position: absolute;
  left: 1.5rem;
  height: 2.8rem;
  width: 2.8rem;
  top: 1rem;
}
.fidcl-card {
  margin: 1.5rem 0;
  background: #fff;
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  -webkit-transition: box-shadow 0.1s ease-in;
  transition: box-shadow 0.1s ease-in;
  border-radius: 0.3rem;
}
.fidcl-card h6 {
  text-align: center;
  color: #522e76;
  margin: 1.5rem 0;
}
.fidcl-card:hover {
  box-shadow: 0 0 1rem 0rem hsla(0, 0%, 64%, 0.14);
  -webkit-transition: box-shadow 0.1s ease-in;
  transition: box-shadow 0.1s ease-in;
}
.fidcl-card.fidcl-card-product:hover button {
  background-color: #522e76;
  color: #fff;
  -webkit-transition: background-color 0.1s ease-in;
  transition: background-color 0.1s ease-in;
}
.fidcl-card-product {
  min-height: 27rem;
  position: relative;
}
.fidcl-card-product .fidcl-button {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
}
@media (max-width: 767px) {
  .fidcl-card-product-horizontal-mobile {
    padding-left: 50%;
    min-height: 20.5rem !important;
  }
  .fidcl-card-product-horizontal-mobile > .img-container {
    position: absolute;
    left: 1.5rem;
    width: 50%;
  }
  .fidcl-card-product-horizontal-mobile h6 {
    padding-left: 3rem;
    text-align: left;
    margin: 0;
  }
}
.fidcl-alert {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
}
.fidcl-alert-with-icon {
  padding-left: 4.5rem;
}
.fidcl-alert-with-icon .fidcl-alert--icon {
  position: absolute;
  left: 1.5rem;
  top: 1.3rem;
}
.fidcl-alert-with-icon .fidcl-alert--icon svg {
  width: 2.2rem;
}
.fidcl-alert > .fidcl-button {
  margin-top: 0.75rem;
}
.spinner {
  width: 20px;
  height: 20px;
  -webkit-animation: contanim 2s linear infinite;
  animation: contanim 2s linear infinite;
}
.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.spinner svg:first-child circle {
  display: none;
  stroke: #522e76;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 2s ease 0.2s infinite;
  animation: strokeanim 2s ease 0.2s infinite;
}
.spinner svg:first-child circle,
.spinner svg:nth-child(2) circle {
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.spinner svg:nth-child(2) circle {
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 6s ease 0.4s infinite;
  animation: strokeanim 6s ease 0.4s infinite;
}
.spinner svg:nth-child(3) circle {
  display: none;
  stroke: #522e76;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 4s ease 0.6s infinite;
  animation: strokeanim 4s ease 0.6s infinite;
}
.spinner svg:nth-child(3) circle,
.spinner svg:nth-child(4) circle {
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.spinner svg:nth-child(4) circle {
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 4s ease 0.8s infinite;
  animation: strokeanim 4s ease 0.8s infinite;
}
.loader-container.arc-scale.loader-thin .spinner circle {
  stroke-width: 6px;
}
@-webkit-keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  to {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}
@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  to {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}
@-webkit-keyframes contanim {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes contanim {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.date-range-sud > div {
  width: 50%;
}
.date-sud {
  position: relative;
}
.date-sub--open {
  display: block;
}
.date-sub--close {
  display: none;
}
.date-sud__calendar {
  position: absolute;
  top: 5.2rem;
  z-index: 100;
  background-color: #fff;
  border: 0.1rem solid #e6e6e6;
  height: 24.5rem;
  width: 30rem;
  left: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
  overflow: hidden;
}
.date-sud__calendar button:focus {
  outline: none;
  background-color: #d9d9d9 !important;
}
.date-sud .show {
  display: block;
}
.date-sud .hidden {
  display: none;
}
.date-sud .date-sud__day {
  width: 14.28%;
  height: 3.13rem;
  font-size: 1.4rem;
  text-align: center;
  line-height: 2.2;
  float: left;
  position: relative;
  background: none;
  border: none;
}
.date-sud .date-sud__day:not(:disabled) {
  cursor: pointer;
}
.date-sud .date-sud__day.other-month {
  background-color: #f0f0f0;
}
.date-sud .date-sud__day:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__day:nth-child(7n + 1):after {
  border-left: none;
}
.date-sud .date-sud__day:not(:disabled):hover {
  background-color: #f2f2f2;
}
.date-sud .fidcl-input-container input:focus + div {
  display: block;
}
.data-sud__header {
  width: 100%;
  display: block;
  height: 3.5rem;
}
.data-sud__header > * {
  display: block;
  float: left;
  height: 3.5rem;
  font-size: 1.4rem;
  line-height: 2.7;
  border: none;
  background-color: initial;
}
.data-sud__header > button:first-child,
.data-sud__header > button:last-child {
  width: 14.285714285714286%;
  text-align: center;
  line-height: 2.4;
}
.data-sud__header > div:nth-child(2) {
  width: 71.4285714286%;
}
.date-sud__title-month,
.date-sud__title-year {
  float: left;
  background-color: initial;
  height: 100%;
  border: none;
  font-size: 1.4rem;
}
.date-sud__title-month {
  width: auto;
  padding: 0 0.5rem;
  text-transform: capitalize;
}
.date-sud__calendar .date-sud__months button {
  float: left;
  width: 33.3333333%;
  height: 5.24rem;
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 3.6;
  background: none;
  border: none;
}
.date-sud__calendar .date-sud__months button:not(:disabled):hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.date-sud__calendar .date-sud__months button:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__months button:nth-child(3n + 1):after {
  border-left: none;
}
.date-sud__calendar .date-sud__years button {
  float: left;
  width: 33.3333333%;
  height: 4.21rem;
  position: relative;
  text-align: center;
  font-size: 1.4rem;
  text-transform: capitalize;
  line-height: 3;
  background: none;
  border: none;
}
.date-sud__calendar .date-sud__years button:not(:disabled):hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.date-sud__calendar .date-sud__years button:after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 0.1rem solid #e6e6e6;
  border-top: 0.1rem solid #e6e6e6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.date-sud .date-sud__years button:nth-child(3n + 1):after {
  border-left: none;
}
.date-sud__name-days {
  float: left;
  width: 14.285714285714286%;
  padding: 0.5rem;
  text-align: center;
  box-sizing: border-box;
  height: 2.2rem;
}
.date-sud__calendar-second {
  left: 30.16rem;
}
.date-sud__header-info {
  right: 4.32rem;
  top: 1.16rem;
  position: absolute;
  font-size: 1.1rem;
  display: inline-block;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
  color: #1e90ff;
}
.date-sud .date-sud__day.date-sud-in-range-persist {
  background-color: #dadce7;
}
.date-sud .date-sud__day.date-sud-in-range,
.date-sud .date-sud__day.date-sud-in-range:hover {
  background-color: #e0e2eb;
}
.fidcl-loading-modal-container {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
}
.fidcl-loading-modal {
  background: #fff;
  padding: 3rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: box-shadow 0.1s ease-in;
  transition: box-shadow 0.1s ease-in;
  border-radius: 0.3rem;
  width: 50rem;
  text-align: center;
  display: block;
  height: auto;
  margin: auto;
  position: absolute;
  top: calc(30% - 4rem);
  left: 0;
  right: 0;
  max-width: 90%;
}
.fidcl-loading-modal .header {
  background: #fff;
  font-size: 2.4rem;
  color: #522e76;
  position: relative;
  line-height: 1;
  margin-bottom: 1.5rem;
}
.fidcl-loading-modal .content {
  text-align: justify;
}
.fidcl-loading-modal footer {
  display: inline-block;
}
/*# sourceMappingURL=main.cb7d6148.chunk.css.map */
