.container-main-menu {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  color: #212529;
  font-size: 18px;
  font-style: normal;
  font-family: OpenSans-Medium, Serif;
  line-height: 27px;
  z-index: 2;
  backdrop-filter: blur(5px);
}

.container-main-menu.opened {
  width: 100vw;
}
.container-main-menu.closed {
  width: 0;
}

.cmm-down-fixed {
  position: fixed;
  top: 80px;
  opacity: 1;
  -webkit-transition: visibility 0.3s, opacity 0.3s linear; /* Antiguos navegadores WebKit */
  transition: visibility 0.3s, opacity 0.3s linear;
}

.cmm-up-fixed {
  position: fixed;
  top: 109px;
}

.main-menu-overlay {
  overflow-y: hidden;
  overflow-x: hidden;
  overscroll-behavior: contain;
  height: calc(100vh - 109px);
}

.main-menu-overlay-fixed {
  height: calc(100vh - 80px);
}

.main-menu-overlay-fixed-up {
  height: calc(100vh - 109px);
}

.menu-container-fixed-down {
  height: calc(100vh - 80px);
}

.menu-container-fixed-up {
  height: calc(100vh - 109px);
}

.main-menu-overlay.opened {
  width: 400px;
}

.input-search-menu-mobile {
  margin-top: 5px;
  font-size: 16px;
  height: 44px;
  min-width: 300px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  color: #191919;
  padding: 15px;
  width: 100%;
}

.input-search-menu-mobile:focus {
  transition: ease-in box-shadow 0.1s;
  outline: #783184;
  box-shadow: 0 0 0 2px #783184;
}

.input-wrapper-menu-mobile {
  position: relative;
}

.input-icon-menu-mobile {
  color: #191919;
  position: absolute;
  width: 22px;
  height: 22px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px #dbdee5;
  border-left-style: solid;
  padding-left: 5px;
}

.list-dropdown-menu-mobile {
  list-style-type: none;
  padding: 0;
}

.list-dropdown-menu-mobile li {
  color: #212529;
  font-family: OpenSans-Medium, Serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.div-resp-mobile {
  display: none;
}

@media only screen and (max-width: 1350px) {
  .input-search-menu-mobile {
    margin-right: 10px;
    min-width: 260px;
  }
  .input-icon-menu-mobile {
    padding-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1279px) {
  .div-resp-mobile {
    display: block;
  }
  .div-help-center {
    margin-bottom: 100px;
  }
  .title-main-menu {
    color: #212529;
    font-family: OpenSans-Bold, Serif;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
  }
  .buttons-main-menu {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .main-menu-overlay {
    border-top: 26px solid #783184;
    height: 100vh;
  }

  .main-menu-overlay.opened {
    width: 100%;
  }

  .cmm-down-fixed {
    top: 0;
    position: fixed;
  }

  .cmm-up-fixed {
    top: 0;
  }
  .input-icon-menu-mobile {
    width: 22px;
    height: 22px;
    top: 45%;
  }
  .input-wrapper-menu-mobile {
    width: 100%;
  }
  .list-dropdown-menu-mobile {
    padding-inline-start: 0;
  }

  .list-dropdown-menu-mobile li {
    margin-bottom: 20px;
  }
  .title-mobile {
    color: #212529;
    font-family: OpenSans-Bold, Serif;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
  }
  .title-points-mobile {
    color: #783184;
    font-size: 18px;
    font-style: normal;
    font-family: OpenSans-Bold, Serif;
    line-height: 27px;
  }
}
