.referInfo {
  height: 29px;
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  margin-bottom: 5px;
}

.date-three-fields-container {
  flex-direction: column;
  display: flex;
}
.date-three-fields {
  flex-direction: row;
  display: flex;
  gap: 12px;
}

@media (max-width: 991.98px) {
  .referInfo {
    height: 55px;
  }
}

@media (max-width: 400px) {
  .referInfo {
    height: 75px;
  }
}

.styled-select {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACZSURBVHgB7c6xDYMwEAXQf0kspYoiZQE3llAqVsgoTJYRMgIjhIqGhgEoPAASGBBIHOZMjfzL8/e7A2LOnysfaPNOn4/X3drGSh/3ehdeJOBLiv7amFTCXC+Hoh9/24AdkA0s4Zb70AWb1meeg4QLHN+h/dRVVXDMLXbzsjgE+lBAIYSJIEfnqoQFwTUaxg5HJ4keYMTEjOkBRYg4B0o/8BgAAAAASUVORK5CYII=')
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  line-height: normal;
  height: 4.2rem;
  width: 16rem;
  margin: 0px 0px 0px 0px;
  background-color: transparent;
  border-radius: 5px;
  border-width: 1px;
  border-color: rgb(150, 150, 150);
  position: relative;
  color: rgb(104, 104, 104);
  z-index: 1;
  padding-left: 15px;
  transition: ease-in border-color 0.1s;
}

.styled-select :hover {
  -webkit-transition: ease-in border-color 0.1s;
  transition: ease-in border-color 0.1s;
  border-color: hsla(270, 44%, 32%, 1);
}
styled-select :active {
  -webkit-transition: ease-in box-shadow 0.1s;
  transition: ease-in box-shadow 0.1s;
  box-shadow: 0 0 0 3px hsla(270, 44%, 92%, 1);
}

.input-invalid {
  border-color: rgb(245, 36, 46) !important;
}
.input-valid {
  border-color: rgb(245, 36, 46);
}

element.style {
  border-color: rgb(245, 36, 46);
}
.bFHvrc:active {
  box-shadow: 0 0 0 3px hsla(357, 91%, 92%, 1);
}
.bFHvrc:active {
  -webkit-transition: ease-in box-shadow 0.1s;
  transition: ease-in box-shadow 0.1s;
  box-shadow: 0 0 0 3px hsla(270, 44%, 92%, 1);
  box-shadow: 0 0 0 3px hsla(357, 91%, 92%, 1);
}
.bFHvrc:hover {
  -webkit-transition: ease-in border-color 0.1s;
  transition: ease-in border-color 0.1s;
  border-color: hsla(270, 44%, 32%, 1);
}
