.badge-cenco {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  background: #b9b9b9;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  mix-blend-mode: multiply;
  border-radius: 16px;
  font-family: 'OpenSans-SemiBold', serif;
}

.badge-ec-tiendas-participantes {
  background: #f2f4f7;
  color: #2a78c0;
}

.badge-ec-tarjeta-cencosud {
  background: #ffeddf;
  color: #f57c03;
}

.badge-ec-puntos-extra {
  background: #ffeefa;
  color: #e72470;
}

.badge-ec-mi-cupon {
  background: #efe5fc;
  color: #190853;
}

.badge-ec-alianzas {
  background: #daebff;
  color: #002046;
}

.badge-ec-canje {
  background: #d2f4e9;
  color: #017753;
}

.badge-ec-SMK {
  background: #ffeefa;
  color: #e72470;
}

.badge-ec-devolucion {
  background: #efefef;
  color: #444e63;
}

.badge-ec-devolución-de-canje {
  background: #efefef;
  color: #444e63;
}

.badge-ec-pago-de-préstamo-de-puntos {
  background: #efefef;
  color: #444e63;
}

.badge-ec-préstamo-de-puntos {
  background: #efefef;
  color: #444e63;
}

.badge-ec-acumulación-por-adicional {
  background: #ffeefa;
  color: #e72470;
}

.badge-additional {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 3px 1px 3px;
  gap: 6px;
  width: 102px;
  height: 25px;
  left: 2194px;
  top: 931px;
  border-radius: 16px;
  font-family: 'OpenSans-SemiBold', Serif;
  white-space: nowrap;
}

.badge-with-permissions {
  background: #d3eece;
}

.badge-without-permissions {
  background: #fff6ed;
}
