.spinner {
  width: 20px;
  height: 20px;
  -webkit-animation: contanim 2s linear infinite;
  animation: contanim 2s linear infinite;
}

.spinner svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.spinner svg:nth-child(1) circle {
  display: none;
  stroke: hsl(270, 44%, 32%);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 2s calc(0.2s * (1)) ease infinite;
  animation: strokeanim 2s calc(0.2s * (1)) ease infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.spinner svg:nth-child(2) circle {
  /*stroke: hsl(270, 44%, 32%);*/
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 6s calc(0.2s * (2)) ease infinite;
  animation: strokeanim 6s calc(0.2s * (2)) ease infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.spinner svg:nth-child(3) circle {
  display: none;
  stroke: hsl(270, 44%, 32%);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 4s calc(0.2s * (3)) ease infinite;
  animation: strokeanim 4s calc(0.2s * (3)) ease infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.spinner svg:nth-child(4) circle {
  /*stroke: hsl(270, 44%, 32%);*/
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  -webkit-animation: strokeanim 4s calc(0.2s * (4)) ease infinite;
  animation: strokeanim 4s calc(0.2s * (4)) ease infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.loader-container.arc-scale.loader-thin .spinner circle {
  stroke-width: 6px;
}

@-webkit-keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}

@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.548324585;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.6449737549;
  }
}

@-webkit-keyframes contanim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes contanim {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
