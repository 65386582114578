@import './sweetalert.css';
@import './carrusel.css';

/* Tipografía Agencia */
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

@font-face {
  font-family: 'Ubuntu', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'OpenSans-Regular', sans-serif;
  overflow-x: hidden;
  overflow-y: visible;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: visible;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 40px !important;
  /* padding-left: 5px !important; */
  font-family: 'OpenSans-Regular', Serif;
  font-size: 14px;
}

select::after {
  content: 'hola';
  width: 20px;
  height: 20px;
  background: red;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

html {
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

header {
  background: #522e76;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
textarea,
select {
  font-family: 'OpenSans-Regular', Serif;
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, monospace;
}

header img {
  max-height: 7rem;
  margin: 0.5rem 0;
}

.form-container {
  max-width: 50rem;
  margin: auto;
}

.btn {
  border-radius: 0.5rem;
  height: 4.2rem;
  display: block;
  box-sizing: border-box;
  font-size: 1.4rem;
  padding: 0 1.5rem;
  transition: ease-in border-color 0.1s;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin: 3rem 0;
  color: #522e75ff;
}

p,
li {
  font-size: 1.4rem;
}

.fidcl-display-test-secure-password {
  padding: 0;
}

.fidcl-display-test-secure-password li {
  font-size: 1.4rem;
  list-style-type: none;
  margin-top: 0.25rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-header .close {
  font-size: 3rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: hsla(270, 44%, 32%, 1);
  font-size: 2rem;
  margin-top: 0;
}

.mt-6 {
  margin-top: 6rem;
}

.fidcl-terms-container.fidcl-modal {
  max-width: 125rem;
  overflow: hidden;
  height: 95%;
}

.fidcl-terms-container.fidcl-modal .modal-content {
  height: 100%;
}

.fidcl-terms-container.fidcl-modal .modal-body {
  height: 100%;
  overflow: auto;
}

.close {
  font-weight: 100;
}

.f-none {
  flex: none !important;
}

.goto-cenco-container {
  padding-right: 3.5rem;
}

.goto-cenco-container > a {
  display: inline-block;
}

.containerButtonsCyT {
  font-size: 1.8rem;
}

.activeTyC {
  color: #3a2855;
  /* background-color: #f4f4f4; */
  border-left: 4px solid;
}
