.first-title-modal-completeness {
  font-family: 'OpenSans-Bold', Serif;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: #212529;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
}

.second-title-modal-completeness {
  max-width: 600px;
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.footer-modal-completeness {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.modal-completeness-footer {
  display: flex;
  justify-content: right;
  gap: 10px;
  border-top: 1px solid #dbdee5;
}

.modal-completeness-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.modal-completeness-column {
  padding: 12px;
}

.modal-completeness-title {
  width: 286px;
  height: 24px;
  font-family: 'OpenSans-SemiBold', Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.modal-completeness-text {
  width: 286px;
  height: 15px;
  font-family: 'OpenSans-Regular', Serif;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  flex: none;
  order: 1;
  flex-grow: 0;
  text-decoration: none !important;
}

.text-one-line {
  height: 15px;
}

.text-two-line {
  height: 30px;
}

.text-multiple-lines {
  height: 45px;
}

.modal-completeness-break-words {
  /* break words */
  word-wrap: break-word !important;
  white-space: unset !important;
  word-break: break-word;
}

.empty-field-required {
  width: 95px;
  height: 15px;
  font-family: 'OpenSans-SemiBold', Serif;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #9e0901;
  padding-left: 4px;
}

.empty-field-optional {
  width: 54px;
  height: 16px;
  font-family: 'OpenSans-SemiBold', Serif;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #6f6f6f;
  padding-left: 4px;
}

.modal-completeness-body {
  overflow-y: auto;
  float: left;
  position: relative;
}

@media only screen and (max-height: 667px) {
  .modal-completeness-body {
    max-height: 170px;
  }
}

@media (min-height: 668px) and (max-height: 720px) {
  .modal-completeness-body {
    max-height: 251px;
  }
}

@media (min-height: 721px) and (max-height: 740px) {
  .modal-completeness-body {
    max-height: 270px;
  }
}

@media (min-height: 741px) and (max-height: 950px) {
  .modal-completeness-body {
    max-height: 330px;
  }
}

@media only screen and (max-width: 950px) {
  .modal-completeness-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-completeness-column {
    padding: 6px;
  }

  .footer-modal-completeness {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }

  .text-one-line {
    height: 21px;
  }

  .text-two-line {
    height: 42px;
  }

  .text-multiple-lines {
    height: 63px;
  }
}
