.tyc-modal-container {
  width: 100%;
  max-width: 692px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 0.1px);
  position: relative;
  background-color: #ffffff !important;
  border-radius: 12px !important;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
}
.tyc-modal-header {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.tyc-modal-date {
  padding-right: 15px;
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #667085;
  display: flex;
  align-items: center;
}
.tyc-modal-header-member-name {
  text-transform: capitalize;
  color: #212529;
}
.tyc-modal-safe-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  overflow-y: hidden;
}
.tyc-modal-scroll {
  overflow-y: scroll;
}
.tyc-modal-scroll-container {
  flex-grow: 1;
  padding: 20px;
}
.tyc-modal-scroll-style {
  flex: 1;
  padding: 0px 30px;
}
/*;

.scrollContainer: {
    flexGrow: 1;
    padding: 20;
};
buttonContainer: {
    display: 'flex';
    flexDirection: 'row';
    justifyContent: 'center';
    flexWrap: 'wrap';
    paddingBottom: '10px';
};
buttonCloseContainer: {
    display: 'flex';
    flexDirection: 'column';
    justifyContent: 'flex-end';
    alignItems: 'flex-end';
    marginRight: 10;
    position: 'absolute';
    right: 0;
    zIndex: 10;
};
.text: { flex: 1; color: '#686868'; paddingRight: 15;
    paddingLeft: 15 };
*/
