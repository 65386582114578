.custom-style-modal-login {
  width: 490px;
}

.custom-style-header-modal-login {
  padding-bottom: 10px;
}

.custom-style-body-modal-login {
  padding: 15px 20px 20px;
  border-bottom: 1px solid #dbdee5;
}

.forget-password-modal {
  display: flex;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 30px;
}

.footer-modal-login {
  align-self: end;
}

@media only screen and (max-width: 768px) {
  .footer-modal-login {
    width: 100%;
  }
}
