.exchange-successfully-footer-sticky {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40%;
  overflow-y: auto;
  overflow-x: hidden;
}

.exchange-successfully-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.exchange-successfully-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  max-width: 570px;
  width: 100%;
  margin: 0 auto 124px;
  overflow-y: auto;
  overflow-x: hidden;
}

.exchange-successfully-title {
  font-family: 'OpenSans-Bold', Serif;
  font-style: normal;
  font-size: 32px;
  line-height: 48px;
}

.exchange-successfully-subtitle {
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  color: #212529;
}

.exchange-successfully-info-card {
  width: 100%;
}

.exchange-successfully-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 16px 10% 16px 0;
}

.exchange-successfully-primary-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 132px;
  height: 44px;
  background: #200e3b;
  border: 1px solid #200e3b;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 4px;
}

.exchange-successfully-secondary-button {
  width: 146px;
  height: 44px;
}

.exchange-successfully-line {
  height: 2px;
  background: #dbdee5;
  border-radius: 4px;
  width: 100%;
}

.online-info-card-exchange-successfully-p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #200e3b;
  width: 100%;
  padding-top: 8px;
}

.data-break {
  word-wrap: break-word !important;
  white-space: unset !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

.no-underlining {
  text-decoration: none !important;
}

.exchange-successfully-text-header {
  font-family: 'OpenSans-Bold', Serif;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  color: #212529;
}

.exchange-successfully-text-modal {
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
}

.exchange-successfully-header-modal {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  background: #ffffff;
  border-bottom: 1px solid #dbdee5;
  border-radius: 8px 8px 0 0;
}

.exchange-successfully-body-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 27px;
  gap: 8px;
}

.exchange-successfully-buttons-container {
  width: 100%;
  position: fixed;
  background-color: #ffffff !important;
  bottom: 0;
}

@media (max-width: 1279px) {
  .exchange-successfully-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 124px;
    padding: 20px 16px 20px 16px;
    max-width: 100% !important;
  }

  .exchange-successfully-title {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
  }

  .exchange-successfully-buttons {
    flex-direction: column;
    width: 100%;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px;
  }

  .exchange-successfully-primary-button,
  .exchange-successfully-secondary-button {
    width: 100%;
  }
}
