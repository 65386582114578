.numero-serie-btn {
  background: none;
  border: none;
}

.numero-serie-btn:active {
  outline: 0;
}

.input-serie {
  text-transform: uppercase;
}

.input-serie::placeholder {
  text-transform: none !important;
}
