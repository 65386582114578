.profile-container {
  z-index: 99999;
  background-color: #200e3b;
  height: 105px;
  border-radius: 0 0 10px 10px;
  width: 190px;
  color: white;
  transition: width 0.3s;
  position: fixed;
  right: 2em;
  margin-top: -12px;
}
profile-container.up {
  position: fixed;
  top: 29px;
}
profile-container.down {
  position: fixed;
  top: 0;
}
profile-container.top {
  position: fixed;
  top: 29px;
}

.profile-tras {
  width: 277px;
  right: 19px;
}

.profile-container-responsive {
  padding-bottom: 16px;
  color: white;
  height: auto;
  width: 255px;
  right: 0px;
  border-radius: 0px 0px 10px 10px;
}

.menu-profile-responsive {
  padding-bottom: 16px;
  background-color: #200e3b;
  color: white;
  position: absolute;
  height: auto;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  top: 85px;
  left: 0px;
}

.menu-profile {
  padding-bottom: 16px;
  z-index: 99999;
  background-color: #200e3b;
  color: white;
  position: fixed;
  height: auto;
  width: 277px;
  right: 19px;
  /* pacity: 0.95;
    filter:  alpha(opacity=50);*/
  border-radius: 0px 0px 10px 10px;
}

.ul-menu-responsive {
  padding-left: 4px;
  margin: 2em;
}

.menu-profile-responsive .li-menu {
  list-style: none;
  font-size: larger;
  font-weight: 100;
}
@media (max-width: 320px) {
  .menu-profile-responsive .li-menu {
    max-height: 45px;
  }
}

.ul-menu {
  margin-top: 8em;
  padding-left: 31px;
}

.menu-profile .li-menu {
  list-style: none;
  font-size: larger;
  font-weight: 100;
}

.up-menu {
  cursor: pointer;
  transform: rotate(-180deg);
  animation: up 0.3s;
}

.down-menu {
  cursor: pointer;
  transform: rotate(0deg);
  animation: down 0.3s;
}

.opacity-profile {
  opacity: 0.1;
  filter: alpha(opacity=50);
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

.fade-out {
  opacity: 0.95;
  animation-name: fadeinout;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 0.2s;
}

@keyframes down {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes up {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0.95;
  }
  100% {
    opacity: 0;
  }
}

.point-menu {
  font-size: 20px;
  letter-spacing: 2px;
}

.c-white {
  color: 'white';
}
.mc-1 {
  margin: 1px;
}
.mc-2 {
  margin: 2px;
}
.mc-5 {
  margin: 5px;
}
.w-14em-nav-item {
  width: 12em;
}
