.menu-dropdown {
  margin-top: 10px;
  position: fixed;
  min-width: 236px;
  background: #f9fafb;
  line-height: 24px;
  border-radius: 6px;
  box-shadow: 0 1px 0 -1px rgba(15, 24, 40, 0.05),
    0 0 0 -1px rgba(16, 24, 40, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.25),
    0 1px 1px 0 rgba(0, 0, 0, 0.25);
  gap: 16px;
  z-index: 1;
  padding-left: 16px;
}

.menu-dropdown-icon {
  width: 16px;
  height: 16px;
  gap: 0;
}

.menu-dropdown-arrow::before {
  content: '';
  position: absolute;
  top: -9px;
  left: 90%;
  transform: translateX(-50%);
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: #f9fafb transparent;
}

.menu-dropdown ul {
  display: -webkit-box; /* Antiguos navegadores WebKit */
  display: -ms-flexbox; /* Antiguos IE */
  display: flex;
  text-align: start;
  -webkit-box-orient: vertical; /* Antiguos navegadores WebKit */
  -webkit-box-direction: normal; /* Antiguos navegadores WebKit */
  -ms-flex-direction: column; /* Antiguos IE */
  flex-direction: column;
  gap: 18px;
  margin-block-start: 12px;
  margin-block-end: 12px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 12px;
  list-style-type: none;
}

.menu-dropdown-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-inline-start: 12px;
  gap: 5px;
  justify-items: center;
}

.menu-dropdown li button {
  text-align: start;
  list-style-type: none;
  font-family: OpenSans-Medium, Serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

@media (hover: hover) {
  .menu-dropdown li button:hover {
    color: #783184;
    font-family: OpenSans-Bold, Serif;
  }
}

.logout-border {
  border-top: 1px solid #dbdee5;
  padding-top: 14px;
}

.menu-dropdown-my-account-spc {
  margin-top: 60px !important;
}
