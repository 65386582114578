.link-puntos-cenco {
  color: #783184;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
}

.link-puntos-cenco-secondary {
  color: #ffffff;
}

.link-puntos-cenco:hover:not([disabled]) {
  color: #783184;
  text-decoration: underline !important;
}
.link-puntos-cenco:focus {
  color: #783184;
}

.link-puntos-cenco-secondary:hover:not([disabled]) {
  color: #eaebec;
  text-decoration: underline !important;
}

.link-puntos-cenco-medium {
  font-size: 18px;
}
.link-white {
  color: #ffffff;
}
.link-purple {
  color: #200e3b;
}

.link-puntos-cenco-disabled {
  color: #c4c5c9;
  pointer-events: none;
}

.icon-left svg {
  margin-right: 3px;
}
.icon-right svg {
  margin-left: 3px;
}
