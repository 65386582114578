.toast-container {
  /* background: #ffe3e5; */
  gap: 5px;
  height: 44px;
  padding: 8px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
}
