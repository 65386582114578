.pre-header-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.pre-header-container.top {
  position: fixed;
}
.pre-header-container.hidden {
  visibility: hidden;
  opacity: 0;
}
.pre-header-container.up {
  position: fixed;
  z-index: 2;
}

/* Add vendor prefixes for position and transition */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .pre-header-container {
  }
}

.pre-header {
  background: #333333 !important;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.pre-header-link {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  height: 29px;
  padding-left: 10px;
  padding-right: 10px;
}

.pre-header-box-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
}

.pre-header-link:hover {
  background: #212121;
}

.pre-header-link a {
  color: white;
}

@media only screen and (max-width: 768px) {
  .pre-header-container {
    background: #333333 !important;
  }

  .pre-header {
    width: 100% !important;
    justify-content: center;
    grid-auto-columns: 1fr;
  }

  .no-mobile {
    width: 0px;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .pre-header-container {
    padding-left: 33%;
    padding-right: 40px;
    background: #333333 !important;
  }

  /* Add vendor prefixes for padding */
  @supports (-ms-ime-align: auto) {
    .pre-header-container {
      -ms-padding-left: 33%;
      -ms-padding-right: 40px;
    }
  }

  .pre-header-link-ca {
    width: 130px !important;
  }
}
