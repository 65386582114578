.exchange-alert-container {
  padding-top: 12px;
}

.exchange-alert-first-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  background: #ebe0ed;
  border-radius: 8px;
}

.exchange-alert-second-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  width: 80%;
  gap: 12px;
}

.exchange-alert-third-container {
  width: 100%;
}

.exchange-alert-title {
  width: 100%;
  font-family: 'OpenSans-Bold', Serif;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
}

.exchange-alert-text {
  width: 100%;
  font-family: 'OpenSans-Medium', Serif;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212529;
}

.exchange-alert-button {
  font-weight: 500;
  width: 210px;
}

@media only screen and (max-width: 1279px) {
  .exchange-alert-first-container {
    flex-direction: column;
    width: 100%;
  }

  .exchange-alert-second-container {
    align-items: flex-start;
    width: 100%;
  }

  .exchange-alert-button {
    width: 100%;
  }
}
